/** @format */

import Button from '../button/button.componenbt'
import styles from './navigation.module.css'
import logo from '../../Andreas.compressed.svg'

const Navigation = () => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <img src={logo} />
      </div>
      <div className={styles.navigation}>
        <Button label='Über mich' target='/' />
        <Button label='Kontakt' target='/contact' />
        <Button label='Impressum' target='/policy' />
      </div>
    </div>
  )
}

export default Navigation
