/** @format */
import {Suspense, lazy} from 'react'
import { Routes, Route } from 'react-router-dom'
import Navigation from './components/navigation/navigation.component'

import AboutScreen from './screens/about.screen'

import './App.css'

const ContactScreen = lazy(() => import('./screens/contact.screen'))
const PolicyScreen = lazy(() => import('./screens/policy.screen'))

const App = () => {
  return (
    <div className='App'>
      <Navigation />
      <Suspense fallback={<div>Laden...</div>}>
      <Routes>
        <Route path='/' element={<AboutScreen />} />
        <Route path='/contact' element={<ContactScreen />} />
        <Route path='/policy' element={<PolicyScreen />} />
      </Routes>
      </Suspense>
    </div>
  )
}

export default App
