/** @format */

import Page from "../components/page/page.component"
import Picture from "../components/picture/picture.component"

const AboutScreen = () => {
  return <Page>
    <Picture />
    <h1>Über mich</h1>
    <p>Ich heiße Andreas Beckers und bin seit über 25 Jahren Software-Entwickler.</p>
    <p>Ob Backend, Frontend oder Schnittstellen, ob Java, Kotlin, Javascript oder Go, ob on Premise oder Cloud, egal was, ich kenne mich damit aus.</p>
  </Page>
}

export default AboutScreen
