/** @format */

import { Link } from 'react-router-dom'
import styles from './button.module.css'

const Button = ({ label, target }) => {
  return (
    <div className={styles.button}>
      <Link to={target}>{label}</Link>
    </div>
  )
}

export default Button
